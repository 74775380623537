import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db, getImageUrl } from "../firebase/firebase";
import { EmpityBox } from "../componentes/Decoracion";
import { FaWhatsappSquare, FaLink } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import { MyContext } from "../context/Context";
import { isMobile } from "react-device-detect";
import { Notas3Solas } from "../componentes/Notas";

export const NotasLeer = () => {
  return(
    <>
      {isMobile ? <IsMobileNotasLeer /> : <IsPcNotasLeer  />}
    </>
  )
}
 const IsPcNotasLeer= () => {
  const { id } = useParams(); // Obtén el id de la URL
  const [note, setNote] = useState(null);
  const [noteData, setNoteData] = useState([]); // Define noteData en el estado
  const [imageUrl, setImageUrl] = useState(null);
  const [ setCopied] = useState(false);
  const currentUrl = window.location.href;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(currentUrl)
      .then(() => setCopied(true))
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`;
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const docRef = doc(db, "NotaCategoria", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setNote(data);

          if (data.idImgNotes) {
            const storage = getStorage();
            const imageRef = ref(storage, data.idImgNotes);

            getDownloadURL(imageRef)
              .then((url) => {
                setImageUrl(url);
              })
              .catch((error) => {
                console.error("Error getting image URL:", error);
                setImageUrl(null);
              });
          }
        } else {
          console.error("Nota no encontrada");
        }
      } catch (error) {
        console.error("Error al cargar la nota: ", error);
      }
    };

    fetchNote();
  }, [id]);

  useEffect(() => {
    const loadNoteData = async () => {
      const data = await fetchNoteData();
      setNoteData(data);
    };

    if (note) {
      loadNoteData();
    }
  }, [note]);

  const fetchNoteData = async () => {
    const noteData = await Promise.all(
      Array.from({ length: 50 }, async (_, i) => {
        const imgSecundario = note[`imgSecundario${i + 1}`];
        const imageUrl = imgSecundario ? await getImageUrl(imgSecundario) : null;
        const youtubeLink = note[`youtubeLink${i + 1}`]; // Asegúrate de tener este campo en Firebase
  
        return {
          imageUrl,
          cita: note[`cita${i + 1}`],
          subtitulo: note[`subtitulo${i + 1}`],
          epigrafoImgSecundario: note[`epigrafoImgSecundario${i + 1}`],
          textContent: note[`textContent${i + 2}`],
          youtubeLink, // Añadir el campo youtubeLink aquí
        };
      })
    );
  
    return noteData;
  };

  if (!note) {
    return (
      <div className="m_w_100vw h-100 d-flex justify-content-center align-items-center">
        <p className="text-white">Cargando nota...</p> {/* Mensaje de carga mientras se obtiene la nota */}
      </div>
    )
  }

  return (
    <div className={`w-100 d-flex flex-column justify-content-center align-items-center bg-white`}>
      <EmpityBox />
      <div className="m_w_70 text-start justify-content-start mt-5">
        <div className="d-flex flex-column mb-5">
          <div className="">
            <span className="m_bg_amarillo rounded-2 text-black py-1 px-2">{note.tipo.toUpperCase()}</span>
          </div>
          <h2 className="my-5 fs-1 fw-bold m_font_primary">{note.title.toUpperCase()}</h2>
          <p className="fs-4">{note.subTitle}</p>
          <span className="mt-2 fs-5">Por: <span className="fw-bold">{note.autor}</span></span>
        </div>

        <div className="">
          {imageUrl && <img className="w-100" src={imageUrl} alt={note.tittle} />}
          <p className="fw-bold">{note.epigrafoImgPrincipal}</p>
        </div>
        <div className="m_text_note">
          <p className="text-justify fs-5">{note.textContent1}</p>
        </div>
        {note.youtubeLink && note.youtubeLink !== '' && (
          <iframe
            height="450"
            className="w-100"
            src={note.youtubeLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        )}
       

        {/* Renderizar noteData */}
        {noteData.map((data, index) => (
          <div key={index} className="m_text_note">
            {data.imageUrl && (
              <img src={data.imageUrl} alt={`imgSecundario${index + 1}`} className="w-100" />
            )}
            {data.epigrafoImgSecundario && (
              <p className="fw-bold fs-5">{data.epigrafoImgSecundario}</p>
            )}
            {data.cita && <p className="fw-bold m_font_primary fs-2 text-center my-4">{data.cita}</p>}
            {data.youtubeLink && <iframe className="w-100"  height="560" src={data.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>}
            {data.subtitulo && <p className="fw-bold m_font_primary fs-3 mt-5 mb-3 pt-2 ">{data.subtitulo}</p>}
            {data.textContent && <p className="text-justify fs-5">{data.textContent}</p>}
          </div>
        ))}


      </div>
      {/* Botones para compartir */}
      <div className="m_share_buttons d-flex flex-column">
        <a href="#" onClick={handleTwitterShare} target="_blank" rel="noopener noreferrer">
          <FaSquareXTwitter className="m_color_primary" size={30} />
        </a>
        <a href="#" onClick={handleWhatsAppShare} target="_blank" rel="noopener noreferrer">
          <FaWhatsappSquare className="m_color_primary my-2" size={30} />
        </a>
        <button onClick={handleCopyClick}>
          <FaLink className="m_color_primary border-none" size={30} />
        </button>
      </div>

      {/*Icono creador nota */}

      {/*Espacio decorativo */}
      <div className="mb-5 pb-5">
        <EmpityBox />
      </div>
    </div>
  );
};
const IsMobileNotasLeer =()=>{
  const { id } = useParams(); // Obtén el id de la URL
  const [note, setNote] = useState(null);
  const [noteData, setNoteData] = useState([]); // Define noteData en el estado
  const [imageUrl, setImageUrl] = useState(null);
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  const { admLogeado } = useContext(MyContext);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(currentUrl)
      .then(() => setCopied(true))
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`;
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const docRef = doc(db, "NotaCategoria", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setNote(data);

          if (data.idImgNotes) {
            const storage = getStorage();
            const imageRef = ref(storage, data.idImgNotes);

            getDownloadURL(imageRef)
              .then((url) => {
                setImageUrl(url);
              })
              .catch((error) => {
                console.error("Error getting image URL:", error);
                setImageUrl(null);
              });
          }
        } else {
          console.error("Nota no encontrada");
        }
      } catch (error) {
        console.error("Error al cargar la nota: ", error);
      }
    };

    fetchNote();
  }, [id]);

  useEffect(() => {
    const loadNoteData = async () => {
      const data = await fetchNoteData();
      setNoteData(data);
    };

    if (note) {
      loadNoteData();
    }
  }, [note]);

  const fetchNoteData = async () => {
    const noteData = await Promise.all(
      Array.from({ length: 50 }, async (_, i) => {
        const imgSecundario = note[`imgSecundario${i + 1}`];
        const imageUrl = imgSecundario ? await getImageUrl(imgSecundario) : null;
        const youtubeLink = note[`youtubeLink${i + 1}`]; // Asegúrate de tener este campo en Firebase
  
        return {
          imageUrl,
          cita: note[`cita${i + 1}`],
          subtitulo: note[`subtitulo${i + 1}`],
          epigrafoImgSecundario: note[`epigrafoImgSecundario${i + 1}`],
          textContent: note[`textContent${i + 2}`],
          youtubeLink, // Añadir el campo youtubeLink aquí
        };
      })
    );
  
    return noteData;
  };

  if (!note) {
    return (
      <div className="m_w_100vw h-100 d-flex justify-content-center align-items-center">
        <p className="text-white">Cargando nota...</p> {/* Mensaje de carga mientras se obtiene la nota */}
      </div>
    )
  }

  return (
    <div className={`w-100 d-flex flex-column justify-content-center align-items-center bg-white`}>
      <EmpityBox />
      <div className="m_w_90 text-start justify-content-start mt-5">
        <div className="d-flex flex-column mb-5">
          <div className="">
            <span className="m_bg_amarillo text-black p-1 m_p_1">{note.tipo.toUpperCase()}</span>
          </div>
          <h2 className="my-5 fs-1 fw-bold m_font_primary">{note.title.toUpperCase()}</h2>
          <p className="fs-4">{note.subTitle}</p>
          <span className="mt-2 fs-5">Por: <span className="fw-bold">{note.autor}</span></span>
        </div>

        <div className="">
          {imageUrl && <img className="w-100" src={imageUrl} alt={note.tittle} />}
          <p className="fw-bold">{note.epigrafoImgPrincipal}</p>
        </div>
        <div className="m_text_note">
          <p className="text-justify">{note.textContent1}</p>
        </div>
        {note.youtubeLink && note.youtubeLink !== '' && (
          <iframe
            height="450"
            className="w-100"
            src={note.youtubeLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        )}
       

        {/* Renderizar noteData */}
        {noteData.map((data, index) => (
          <div key={index} className="m_text_note">
            {data.imageUrl && (
              <img src={data.imageUrl} alt={`imgSecundario${index + 1}`} className="w-100" />
            )}
            {data.epigrafoImgSecundario && (
              <p className="fw-bold fs-5">{data.epigrafoImgSecundario}</p>
            )}
            {data.cita && <p className="fw-bold fs-2 text-center my-4">{data.cita}</p>}
            {data.youtubeLink && <iframe className="w-100"  height="560" src={data.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>}
            {data.subtitulo && <p className="fw-bold fs-3 mt-5 mb-3 pt-2 ">{data.subtitulo}</p>}
            {data.textContent && <p className="text-justify">{data.textContent}</p>}
          </div>
        ))}


      </div>
      {/* Botones para compartir */}
      <div className="m_share_buttons d-flex flex-column">
        <a href="#" onClick={handleTwitterShare} target="_blank" rel="noopener noreferrer">
          <FaSquareXTwitter className="m_color_primary" size={30} />
        </a>
        <a href="#" onClick={handleWhatsAppShare} target="_blank" rel="noopener noreferrer">
          <FaWhatsappSquare className="m_color_primary my-2" size={30} />
        </a>
        <button onClick={handleCopyClick}>
          <FaLink className="m_color_primary border-none" size={30} />
        </button>
      </div>

      {/*Espacio decorativo */}
      <div className="mb-5 pb-5">
        <EmpityBox />
      </div>
    </div>
  );
}

