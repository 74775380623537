import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MyContext } from '../context/Context';
import { db, storage } from '../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const AdminDashboard = () => {
  const { admLogeado } = useContext(MyContext);
  const [ setImageUrl] = useState(''); 
  const [addNote, setAddNote] = useState({
    tipo: '',
    title: '',
    imgIdPrincipal: '',
    idImgNotes: '',
    epigrafoImgPrincipal: '',
    subtitulo: '',
    subTitle: '',
    autor: '',
    textContent1: '',
    updateUser: '',
    descripcionNota:''
  });

  const [fieldCount, setFieldCount] = useState({
    textContent: 1,
    cita: 1,
    subtitulo: 1,
    imgSecundario: 1,
    epigrafoImgSecundario: 1
  });
  

  if (admLogeado === undefined) {
    return <p>Cargando...</p>;
  }

  if (!admLogeado) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddNote({
      ...addNote,
      [name]: value
    });
  };

  const handleAddField = (fieldType) => {
    setFieldCount(prevCount => {
      const newCount = prevCount[fieldType] + 1;
  
      setAddNote(prevNote => {
        const newFields = { ...prevNote };
        
        if (fieldType === 'imgSecundario') {
          newFields[`${fieldType}${newCount}`] = ''; // Campo de imagen secundaria
          newFields[`epigrafoImgSecundario${newCount}`] = ''; // Campo de epígrafe
  
          setFieldCount(prevCount => ({
            ...prevCount,
            [fieldType]: newCount,
            epigrafoImgSecundario: prevCount.epigrafoImgSecundario + 1
          }));
        } else {
          newFields[`${fieldType}${newCount}`] = '';
          setFieldCount(prevCount => ({
            ...prevCount,
            [fieldType]: newCount
          }));
        }
  
        return newFields;
      });
  
      return {
        ...prevCount,
        [fieldType]: newCount,
        textContent: prevCount.textContent + 1,
        cita: prevCount.cita + 1,
        subtitulo: prevCount.subtitulo + 1,
        imgSecundario: prevCount.imgSecundario + 1,
        epigrafoImgSecundario: prevCount.epigrafoImgSecundario +1
      };
    });
  };
  

  const handleImageChange = async (e, fieldKey) => {
    const file = e.target.files[0];
    if (file) {
      const idUnica = uuidv4();
      const imageRef = ref(storage, idUnica);

      try {
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);

        setAddNote(prevState => ({
          ...prevState,
          [fieldKey]: idUnica // Guarda la ID de la imagen en el campo correspondiente
        }));
      } catch (error) {
        console.error('Error al subir imagen:', error);
      }
    }
  };
  const handleImageChange2 = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const idUnica = uuidv4(); // Genera un ID único para la imagen
      const imageRef = ref(storage, idUnica); // Define la referencia en Storage

      try {
        await uploadBytes(imageRef, file); // Sube la imagen
        const url = await getDownloadURL(imageRef); // Obtén la URL de la imagen
        setImageUrl(url); // Actualiza el estado con la URL de la imagen
        console.log('Imagen subida exitosamente:', url);
          setAddNote(prevState => ({
            ...prevState,
            idImgNotes: idUnica // Actualiza el estado con la ID de la imagen
          }));
        //console.log(addNote)
      } catch (error) {
        console.error('Error al subir imagen:', error);
      }
    }
  };

  const getLabel = (key) => {
    if (key.startsWith('textContent')) return 'Parrafo';
    if (key.startsWith('cita')) return 'Cita';
    if (key.startsWith('subtitulo')) return 'Subtítulo';
    if (key.startsWith('imgSecundario')) return 'Imagen ';
    if (key.startsWith('epigrafoImgSecundario')) return 'Epígrafe de Imagen';
    return key;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setFieldCount({
      textContent: 1,
      cita: 1,
      subtitulo: 1
    });
  
    const idUnica = uuidv4();
    const noteRef = doc(db, 'NotaCategoria', idUnica); // Usa `doc` de `firebase/firestore`
    
    try {
      await setDoc(noteRef,  addNote);
      console.log('Datos enviados exitosamente');
    } catch (error) {
      console.error('Error al enviar datos:', error);
    }
  
    console.log(addNote);
    window.location.reload();
  
    setAddNote({
      tipo: '',
      title: '',
      subtitulo: '',
      subTitle:'',
      autor: '',
      textContent1: '',
      updateUser: '',
      epigrafoImgPrincipal:'',
      descripcionNota:''
    });
  };

  return (
    <div className="d-flex text-white">
      <form className="mt-4 pt-4 w-50 d-flex justify-content-center" onSubmit={handleSubmit}>
        <div className="d-flex flex-column ms-4 rounded-2 p-4 bg-danger w-100 bg-dark">
          <div className="text-center">
            <p className="fs-4">Subir Nota</p>
          </div>

          {/* Tipo */}
          <div className="m_ctn_input_card_note d-flex ">
            <label className="">Tipo:</label>
            <input
              type="text"
              name="tipo"
              value={addNote.tipo}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>

          {/* Título */}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Título:</label>
            <input
              type="text"
              name="title"
              value={addNote.title}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>
          {/* descripcion Nota*/}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Descripcion Nota:</label>
            <input
              type="text"
              name="descripcionNota"
              value={addNote.descripcionNota}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>
          {/* Subtítulo */}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Subtítulo Principal:</label>
            <input
              type="text"
              name="subTitle"
              value={addNote.subTitle}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>
          
           {/* Autor */}
           <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Autor:</label>
            <input
              type="text"
              name="autor"
              value={addNote.autor}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>

          {/* img */}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Imagen:</label>
            <input
              type="file"
              onChange={handleImageChange2}
              className="rounded-2 w-100"
            />
          </div>

          {/* Epigrafo img principal */}
          <div className="m_ctn_input_card_note d-flex my-2">
            <label className="">Epigrafe Imagen Principal:</label>
            <input
              type="text"
              name="epigrafoImgPrincipal"
              value={addNote.epigrafoImgPrincipal}
              onChange={handleChange}
              className="rounded-2 w-100"
            />
          </div>

          {/* Campos Dinámicos */}
          {Object.keys(addNote).map(key => {
            if (
              key.startsWith('textContent') ||
              key.startsWith('cita') ||
              key.startsWith('subtitulo') ||
              key.startsWith('imgSecundario') ||
              key.startsWith('epigrafoImgSecundario')
            ) {
              return (
                <div key={key} className="m_ctn_input_card_note d-flex my-2">
                  <label className="">{getLabel(key)}:</label>
                  {key.startsWith('imgSecundario') ? (
                    <input
                      type="file"
                      name={key}
                      onChange={(e) => handleImageChange(e, key)}
                      className="rounded-2 w-100"
                    />
                  ) : (
                    <textarea
                      type="text"
                      name={key}
                      value={addNote[key]}
                      onChange={handleChange}
                      className="rounded-2 w-100"
                    />
                  )}
                </div>
              );
            }
            return null;
          })}

          {/* Botones para Agregar Campos */}
          <div className="m_ctn_input_card_note d-flex justify-content-between">
            <button
              className='text-white btn btn-primary'
              type="button"
              onClick={() => handleAddField('textContent')}
            >
              Agregar Texto
            </button>

            <button
              className='text-white btn btn-primary'
              type="button"
              onClick={() => handleAddField('cita')}
            >
              Agregar Cita
            </button>

            <button
              className='text-white btn btn-primary'
              type="button"
              onClick={() => handleAddField('subtitulo')}
            >
              Agregar Subtítulo
            </button>

            <button
              className='text-white btn btn-primary'
              type="button"
              onClick={() => handleAddField('imgSecundario')}
            >
              Agregar Imagen
            </button>
          </div>

          {/* Botón para Guardar Nota */}
          <button className="btn btn-primary w-100 mt-5" type="submit">Guardar Nota</button>

        </div>
      </form>
    </div>
  );
};
