import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import { MyContext } from "../context/Context";
import { db, storage } from "../firebase/firebase";
import { isMobile } from "react-device-detect";

export const Notas= () => { 
  return(
    <>
      {isMobile ? <NotasMobile/> : <NotasPC/>}
    </>
  )
}
const NotasPC = () => {
  const { admLogeado } = useContext(MyContext);
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "NotaCategoria"));
        const notesData = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const idImgNotes = data.idImgNotes;
          const imgUserNota = data.imgUserNota;
          let imgUrl = null;
          let userImgUrl = null;

          try {
            if (idImgNotes) {
              imgUrl = await getDownloadURL(ref(storage, idImgNotes));
            }
          } catch (error) {
            console.error(`Error fetching image URL for note ${doc.id}: `, error);
          }

          try {
            if (imgUserNota) {
              userImgUrl = await getDownloadURL(ref(storage, imgUserNota));
            }
          } catch (error) {
            console.error(`Error fetching user image URL for note ${doc.id}: `, error);
          }

          return { id: doc.id, ...data, imgUrl, userImgUrl };
        });

        const resolvedNotesData = await Promise.allSettled(notesData);

        // Filtrar solo los resultados que fueron exitosos
        const successfulNotes = resolvedNotesData
          .filter(result => result.status === 'fulfilled')
          .map(result => result.value);

        // Ordenar las notas por 'notaNumero' de mayor a menor
        setNotes(
          successfulNotes.sort((a, b) => b.notaNumero - a.notaNumero)
        );
      } catch (error) {
        console.error("Error fetching notes: ", error);
      }
    };

    fetchNotes();
  }, []);

  const handleDelete = async (id, idImgNotes, imgUserNota) => {
    try {
      await deleteDoc(doc(db, "NotaCategoria", id));
      if (idImgNotes) {
        const imageRef = ref(storage, idImgNotes);
        await deleteObject(imageRef);
      }
      if (imgUserNota) {
        const userImageRef = ref(storage, imgUserNota);
        await deleteObject(userImageRef);
      }
      setNotes(notes.filter(note => note.id !== id));
    } catch (error) {
      console.error("Error deleting document and images: ", error);
    }
  };

  const handleReadMore = (id) => {
    navigate(`/notas/${id}`); // Redirigir al usuario a la página de detalle de la nota
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {notes.map(note => (
          <div key={note.id} className="col-12 col-md-6 col-lg-3 mb-4 pb-5 mx-3" onClick={() => handleReadMore(note.id)}>
            <div className="m_card_note">
              <div className="card h-100 border-0 rounded-0 m_pointer">
                {/* img */ }
                <div className="ñimg-container rounded-0">
                  <img src={note.imgUrl} alt={note.title} className="card-img-top rounded-0" />
                  <div className=""></div>
                  <span className="m_bg_primary text-white fw-bold py-2 text-center span1">{note.tipo.toUpperCase()}</span>
                  <img  className="m_img_user"  src={note.userImgUrl} alt={note.updateUser}/>
                </div>
              </div>
              <div className="pt-4 px-2 m_ctn_title_autor d-flex flex-column align-items-start justify-content-between m_pointer" onClick={() => handleReadMore(note.id)}>
                <h2 className="">{note.title.toUpperCase()}</h2>
                <div className="w-100">
                  <div className="m_line_Nota mb-1"></div>
                  <span className="ñm_color_gray_notas ">Por: <span className="text-white">{note.autor.toUpperCase()}</span></span>
                </div>
              </div>
            </div>
            {admLogeado && (
              <button onClick={() => handleDelete(note.id, note.idImgNotes)} className="btn bg-danger mt-2">
                Eliminar Nota
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const NotasMobile = () => {
  const { admLogeado } = useContext(MyContext);
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "NotaCategoria"));
        const notesData = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const idImgNotes = data.idImgNotes;
          const imgUserNota = data.imgUserNota;
          let imgUrl = null;
          let userImgUrl = null;

          try {
            if (idImgNotes) {
              imgUrl = await getDownloadURL(ref(storage, idImgNotes));
            }
          } catch (error) {
            console.error(`Error fetching image URL for note ${doc.id}: `, error);
          }

          try {
            if (imgUserNota) {
              userImgUrl = await getDownloadURL(ref(storage, imgUserNota));
            }
          } catch (error) {
            console.error(`Error fetching user image URL for note ${doc.id}: `, error);
          }

          return { id: doc.id, ...data, imgUrl, userImgUrl };
        });

        const resolvedNotesData = await Promise.allSettled(notesData);

        // Filtrar solo los resultados que fueron exitosos
        const successfulNotes = resolvedNotesData
          .filter(result => result.status === 'fulfilled')
          .map(result => result.value);

        // Ordenar las notas por 'notaNumero' de mayor a menor
        setNotes(
          successfulNotes.sort((a, b) => b.notaNumero - a.notaNumero)
        );
      } catch (error) {
        console.error("Error fetching notes: ", error);
      }
    };

    fetchNotes();
  }, []);

  const handleDelete = async (id, idImgNotes, imgUserNota) => {
    try {
      await deleteDoc(doc(db, "NotaCategoria", id));
      if (idImgNotes) {
        const imageRef = ref(storage, idImgNotes);
        await deleteObject(imageRef);
      }
      if (imgUserNota) {
        const userImageRef = ref(storage, imgUserNota);
        await deleteObject(userImageRef);
      }
      setNotes(notes.filter(note => note.id !== id));
    } catch (error) {
      console.error("Error deleting document and images: ", error);
    }
  };

  const handleReadMore = (id) => {
    navigate(`/notas/${id}`); // Redirigir al usuario a la página de detalle de la nota
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {notes.map(note => (
          <div key={note.id} className="col-12 col-md-6 col-lg-3 mb-4 pb-5 mx-3" onClick={() => handleReadMore(note.id)}>
            <div className="m_card_note">
              <div className="card h-100 border-0 rounded-0 m_pointer">
                <img className="m_img_userMobile" src={note.userImgUrl} alt={note.updateUser} />
                <div className="ñimg-container rounded-0">
                  <img src={note.imgUrl} alt={note.title} className="card-img-top rounded-0" />
                  <div className=""></div>
                  <span className="m_bg_primary text-white fw-bold py-2 text-center span1">{note.tipo.toUpperCase()}</span>
                </div>
              </div>
              <div className="pt-4 px-2 m_ctn_title_autor d-flex flex-column align-items-start justify-content-between m_pointer" onClick={() => handleReadMore(note.id)}>
                <h2 className="">{note.title.toUpperCase()}</h2>
                <div className="w-100">
                  <div className="m_line_Nota mb-1"></div>
                  <span className="ñm_color_gray_notas">Por: <span className="text-white">{note.autor.toUpperCase()}</span></span>
                </div>
              </div>
            </div>
            {admLogeado && (
              <button onClick={() => handleDelete(note.id, note.idImgNotes)} className="btn bg-danger mt-2">
                Eliminar Nota
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


export const Notas3Solas =()=> {
  return(
    <>
      {isMobile ? <NotasMobile3Solas/> : <NotasPC3Solas/>}
    </>
  )
} 

const NotasPC3Solas= () => {
    const { admLogeado } = useContext(MyContext);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchNotes = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "NotaCategoria"));
          const notesData = querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const idImgNotes = data.idImgNotes;
            const imgUserNota = data.imgUserNota;
            let imgUrl = null;
            let userImgUrl = null;
  
            try {
              if (idImgNotes) {
                imgUrl = await getDownloadURL(ref(storage, idImgNotes));
              }
            } catch (error) {
              console.error(`Error fetching image URL for note ${doc.id}: `, error);
            }
  
            try {
              if (imgUserNota) {
                userImgUrl = await getDownloadURL(ref(storage, imgUserNota));
              }
            } catch (error) {
              console.error(`Error fetching user image URL for note ${doc.id}: `, error);
            }
  
            return { id: doc.id, ...data, imgUrl, userImgUrl };
          });
  
          const resolvedNotesData = await Promise.allSettled(notesData);
  
          const successfulNotes = resolvedNotesData
            .filter(result => result.status === 'fulfilled')
            .map(result => result.value);
  
          // 🔽 Ordenar por notaNumero (de mayor a menor) y tomar solo 3
          const notasOrdenadas = successfulNotes
            .filter(n => typeof n.notaNumero === 'number')
            .sort((a, b) => b.notaNumero - a.notaNumero)
            .slice(0, 3);
  
          setNotes(notasOrdenadas);
        } catch (error) {
          console.error("Error fetching notes: ", error);
        }
      };
  
      fetchNotes();
    }, []);
  
    const handleDelete = async (id, idImgNotes, imgUserNota) => {
      try {
        await deleteDoc(doc(db, "NotaCategoria", id));
        if (idImgNotes) {
          const imageRef = ref(storage, idImgNotes);
          await deleteObject(imageRef);
        }
        if (imgUserNota) {
          const userImageRef = ref(storage, imgUserNota);
          await deleteObject(userImageRef);
        }
        setNotes(notes.filter(note => note.id !== id));
      } catch (error) {
        console.error("Error deleting document and images: ", error);
      }
    };
  
    const handleReadMore = (id) => {
      navigate(`/notas/${id}`);
    };
  
    return (
      <div className="container">
        <div className="row justify-content-center">
          {notes.map(note => (
            <div key={note.id} className="col-12 col-md-6 col-lg-3 mb-4 pb-5 mx-3" onClick={() => handleReadMore(note.id)}>
              <div className="m_card_note">
                <div className="card h-100 border-0 rounded-0 m_pointer">
                  <div className="ñimg-container rounded-0">
                    <img src={note.imgUrl} alt={note.title} className="card-img-top rounded-0" />
                    <span className="m_bg_primary text-white fw-bold py-2 text-center span1">{note.tipo.toUpperCase()}</span>
                    <img className="m_img_user" src={note.userImgUrl} alt={note.updateUser} />
                  </div>
                </div>
                <div className="pt-4 px-2 m_ctn_title_autor d-flex flex-column align-items-start justify-content-between m_pointer">
                  <h2>{note.title.toUpperCase()}</h2>
                  <div className="w-100">
                    <div className="m_line_Nota mb-1"></div>
                    <span className="ñm_color_gray_notas">
                      Por: <span className="text-white">{note.autor.toUpperCase()}</span>
                    </span>
                  </div>
                </div>
              </div>
              {admLogeado && (
                <button onClick={() => handleDelete(note.id, note.idImgNotes, note.imgUserNota)} className="btn bg-danger mt-2">
                  Eliminar Nota
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
}
const NotasMobile3Solas= () => {
  const { admLogeado } = useContext(MyContext);
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "NotaCategoria"));
        const notesData = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const idImgNotes = data.idImgNotes;
          const imgUserNota = data.imgUserNota;
          let imgUrl = null;
          let userImgUrl = null;

          try {
            if (idImgNotes) {
              imgUrl = await getDownloadURL(ref(storage, idImgNotes));
            }
          } catch (error) {
            console.error(`Error fetching image URL for note ${doc.id}: `, error);
          }

          try {
            if (imgUserNota) {
              userImgUrl = await getDownloadURL(ref(storage, imgUserNota));
            }
          } catch (error) {
            console.error(`Error fetching user image URL for note ${doc.id}: `, error);
          }

          return { id: doc.id, ...data, imgUrl, userImgUrl };
        });

        const resolvedNotesData = await Promise.allSettled(notesData);

        const successfulNotes = resolvedNotesData
          .filter(result => result.status === 'fulfilled')
          .map(result => result.value);

        // 🔽 Ordenar por notaNumero (de mayor a menor) y mostrar solo 3
        const notasOrdenadas = successfulNotes
          .filter(n => typeof n.notaNumero === 'number')
          .sort((a, b) => b.notaNumero - a.notaNumero)
          .slice(0, 3);

        setNotes(notasOrdenadas);
      } catch (error) {
        console.error("Error fetching notes: ", error);
      }
    };

    fetchNotes();
  }, []);

  const handleDelete = async (id, idImgNotes, imgUserNota) => {
    try {
      await deleteDoc(doc(db, "NotaCategoria", id));
      if (idImgNotes) {
        const imageRef = ref(storage, idImgNotes);
        await deleteObject(imageRef);
      }
      if (imgUserNota) {
        const userImageRef = ref(storage, imgUserNota);
        await deleteObject(userImageRef);
      }
      setNotes(notes.filter(note => note.id !== id));
    } catch (error) {
      console.error("Error deleting document and images: ", error);
    }
  };

  const handleReadMore = (id) => {
    navigate(`/notas/${id}`);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {notes.map(note => (
          <div key={note.id} className="col-12 col-md-6 col-lg-3 mb-4 pb-5 mx-3" onClick={() => handleReadMore(note.id)}>
            <div className="m_card_note">
              <div className="card h-100 border-0 rounded-0 m_pointer">
                <img className="m_img_userMobile" src={note.userImgUrl} alt={note.updateUser} />
                <div className="ñimg-container rounded-0">
                  <img src={note.imgUrl} alt={note.title} className="card-img-top rounded-0" />
                  <span className="m_bg_primary text-white fw-bold py-2 text-center span1">{note.tipo.toUpperCase()}</span>
                </div>
              </div>
              <div className="pt-4 px-2 m_ctn_title_autor d-flex flex-column align-items-start justify-content-between m_pointer" onClick={() => handleReadMore(note.id)}>
                <h2>{note.title.toUpperCase()}</h2>
                <div className="w-100">
                  <div className="m_line_Nota mb-1"></div>
                  <span className="ñm_color_gray_notas">Por: <span className="text-white">{note.autor.toUpperCase()}</span></span>
                </div>
              </div>
            </div>
            {admLogeado && (
              <button onClick={() => handleDelete(note.id, note.idImgNotes, note.imgUserNota)} className="btn bg-danger mt-2">
                Eliminar Nota
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};