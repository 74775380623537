//park Session entradas

//portadas
import psMarzo24 from './psMarzo24.jpg'
import psMayo24 from './psMayo24.jpg'
import psJulio24 from './psJulio24.jpg'
import psSeptiembre24 from './psSeptiembre24.jpg'
import psNoviembre24 from './psNoviembre24.jpg' 
import psMarzo25 from './psMarzo25.jpg'

//marzo 2024
import marzo2401 from './marzo24/mar2401.jpg'
import marzo2402 from './marzo24/mar2402.jpg'
import marzo2403 from './marzo24/mar2403.jpg'
import marzo2404 from './marzo24/mar2404.jpg'
import marzo2405 from './marzo24/mar2405.jpg'
import marzo2406 from './marzo24/mar2406.jpg'
import marzo2407 from './marzo24/mar2407.jpg'
import marzo2408 from './marzo24/mar2408.jpg'
import marzo2409 from './marzo24/mar2409.jpg'   
import marzo2410 from './marzo24/mar2410.jpg'
import marzo2411 from './marzo24/mar2411.jpg'
import marzo2412 from './marzo24/mar2412.jpg'
import marzo2413 from './marzo24/mar2413.jpg'
import marzo2414 from './marzo24/mar2414.jpg'
import marzo2415 from './marzo24/mar2415.jpg'
import marzo2416 from './marzo24/mar2416.jpg'
import marzo2417 from './marzo24/mar2417.jpg'
import marzo2418 from './marzo24/mar2418.jpg'
import marzo2419 from './marzo24/mar2419.jpg'

// mayo 2024
import mayo2401 from './mayo24/mayo2401.jpg'
import mayo2402 from './mayo24/mayo2402.jpg'
import mayo2403 from './mayo24/mayo2403.jpg'
import mayo2404 from './mayo24/mayo2404.jpg'
import mayo2405 from './mayo24/mayo2405.jpg'
import mayo2406 from './mayo24/mayo2406.jpg'
import mayo2407 from './mayo24/mayo2407.jpg'
import mayo2408 from './mayo24/mayo2408.jpg'
import mayo2409 from './mayo24/mayo2409.jpg'
import mayo2410 from './mayo24/mayo2410.jpg'
import mayo2411 from './mayo24/mayo2411.jpg'
import mayo2412 from './mayo24/mayo2412.jpg'
import mayo2413 from './mayo24/mayo2413.jpg'
import mayo2414 from './mayo24/mayo2414.jpg'
import mayo2415 from './mayo24/mayo2415.jpg'
import mayo2416 from './mayo24/mayo2416.jpg'
import mayo2417 from './mayo24/mayo2417.jpg'
import mayo2418 from './mayo24/mayo2418.jpg'
import mayo2419 from './mayo24/mayo2419.jpg'
import mayo2420 from './mayo24/mayo2420.jpg'
import mayo2421 from './mayo24/mayo2421.jpg'
//julio 2024
import julio2401 from './julio24/julio2401.jpg'
import julio2402 from './julio24/julio2402.jpg'
import julio2403 from './julio24/julio2403.jpg'
import julio2404 from './julio24/julio2404.jpg'
import julio2405 from './julio24/julio2405.jpg'
import julio2406 from './julio24/julio2406.jpg'
import julio2407 from './julio24/julio2407.jpg'
import julio2408 from './julio24/julio2408.jpg'
import julio2409 from './julio24/julio2409.jpg'
import julio2410 from './julio24/julio2410.jpg'
import julio2411 from './julio24/julio2411.jpg'
import julio2412 from './julio24/julio2412.jpg'
import julio2413 from './julio24/julio2413.jpg'
import julio2414 from './julio24/julio2414.jpg'
import julio2415 from './julio24/julio2415.jpg'
import julio2416 from './julio24/julio2416.jpg'
import julio2417 from './julio24/julio2417.jpg'
import julio2418 from './julio24/julio2418.jpg'
import julio2419 from './julio24/julio2419.jpg'

//septiembre 2024
import sep2401 from './septiembre24/sep2401.jpg'
import sep2402 from './septiembre24/sep2402.jpg'
import sep2403 from './septiembre24/sep2403.jpg'
import sep2404 from './septiembre24/sep2404.jpg'
import sep2405 from './septiembre24/sep2405.jpg'
import sep2406 from './septiembre24/sep2406.jpg'
import sep2407 from './septiembre24/sep2407.jpg'
import sep2408 from './septiembre24/sep2408.jpg'
import sep2409 from './septiembre24/sep2409.jpg'
import sep2410 from './septiembre24/sep2410.jpg'
import sep2411 from './septiembre24/sep2411.jpg'
import sep2412 from './septiembre24/sep2412.jpg'
import sep2413 from './septiembre24/sep2413.jpg'
import sep2414 from './septiembre24/sep2414.jpg'
import sep2415 from './septiembre24/sep2415.jpg'
import sep2416 from './septiembre24/sep2416.jpg'

// Noviembre 2024
import nov2401 from './noviembre24/nov2401.jpg'
import nov2402 from './noviembre24/nov2402.jpg'
import nov2403 from './noviembre24/nov2403.jpg'
import nov2404 from './noviembre24/nov2404.jpg'
import nov2405 from './noviembre24/nov2405.jpg'
import nov2406 from './noviembre24/nov2406.jpg'
import nov2407 from './noviembre24/nov2407.jpg'
import nov2408 from './noviembre24/nov2408.jpg'
import nov2409 from './noviembre24/nov2409.jpg'
import nov2410 from './noviembre24/nov2410.jpg'
import nov2411 from './noviembre24/nov2411.jpg'
import nov2412 from './noviembre24/nov2412.jpg'
import nov2413 from './noviembre24/nov2413.jpg'
import nov2414 from './noviembre24/nov2414.jpg'
import nov2415 from './noviembre24/nov2415.jpg'
import nov2416 from './noviembre24/nov2416.jpg'
import nov2417 from './noviembre24/nov2417.jpg'
import nov2418 from './noviembre24/nov2418.jpg'
import nov2419 from './noviembre24/nov2419.jpg'
import nov2420 from './noviembre24/nov2420.jpg'
import nov2421 from './noviembre24/nov2421.jpg'

// Marzo 25
import marzo2501 from './marzo25/marzo2501.jpg'
import marzo2502 from './marzo25/marzo2502.jpg'
import marzo2503 from './marzo25/marzo2503.jpg'
import marzo2504 from './marzo25/marzo2504.jpg'
import marzo2505 from './marzo25/marzo2505.jpg'
import marzo2506 from './marzo25/marzo2506.jpg'
import marzo2507 from './marzo25/marzo2507.jpg'
import marzo2508 from './marzo25/marzo2508.jpg'
import marzo2509 from './marzo25/marzo2509.jpg'   
import marzo2510 from './marzo25/marzo2510.jpg'
import marzo2511 from './marzo25/marzo2511.jpg'
import marzo2512 from './marzo25/marzo2512.jpg'
import marzo2513 from './marzo25/marzo2513.jpg'
import marzo2514 from './marzo25/marzo2514.jpg'
import marzo2515 from './marzo25/marzo2515.jpg'
import marzo2516 from './marzo25/marzo2516.jpg'
import marzo2517 from './marzo25/marzo2517.jpg'
import marzo2518 from './marzo25/marzo2518.jpg'


export const imgTimeLapse  ={
  psMarzo24,
  psMayo24,
  psJulio24,
  psSeptiembre24,
  psNoviembre24,
  psMarzo25,

  marzo2401,
  marzo2402,
  marzo2403,
  marzo2404,
  marzo2405,
  marzo2406,
  marzo2407,
  marzo2408,
  marzo2409,
  marzo2410,
  marzo2411,
  marzo2412,
  marzo2413,
  marzo2414,
  marzo2415,
  marzo2416,
  marzo2417,
  marzo2418,
  marzo2419,

  mayo2401,
  mayo2402,
  mayo2403,
  mayo2404,
  mayo2405,
  mayo2406,
  mayo2407,
  mayo2408,
  mayo2409,
  mayo2410,
  mayo2411,
  mayo2412,
  mayo2413,
  mayo2414,
  mayo2415,
  mayo2416,
  mayo2417,
  mayo2418,
  mayo2419,
  mayo2420,
  mayo2421,

  julio2401,
  julio2402,
  julio2403,
  julio2404,
  julio2405,
  julio2406,
  julio2407,
  julio2408,
  julio2409,
  julio2410,
  julio2411,
  julio2412,
  julio2413,
  julio2414,
  julio2415,
  julio2416,
  julio2417,
  julio2418,
  julio2419,

  sep2401,
  sep2402,
  sep2403,
  sep2404,
  sep2405,
  sep2406,
  sep2407,
  sep2408,
  sep2409,
  sep2410,
  sep2411,
  sep2412,
  sep2413,
  sep2414,
  sep2415,
  sep2416,

  nov2401,
  nov2402,
  nov2403,
  nov2404,
  nov2405,
  nov2406,
  nov2407,
  nov2408,
  nov2409,
  nov2410,
  nov2411,
  nov2412,
  nov2413,
  nov2414,
  nov2415,
  nov2416,
  nov2417,
  nov2418,
  nov2419,
  nov2420,
  nov2421,

  marzo2501,
  marzo2502,
  marzo2503,
  marzo2504,
  marzo2505,
  marzo2506,
  marzo2507,
  marzo2508,
  marzo2509,
  marzo2510,
  marzo2511,
  marzo2512,
  marzo2513,
  marzo2514,
  marzo2515,
  marzo2516,
  marzo2517,
  marzo2518,

}
